var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        "destroy-on-close": true,
        width: 650,
        title: _vm.$t("lbl_add_consume_product")
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-form-model",
            { attrs: { model: _vm.form } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_product_code") } },
                [
                  _c("CSelectMasterProductCode", {
                    attrs: { "prop-search-by": _vm.searchByProduct },
                    on: {
                      "on-select": function(e) {
                        return _vm.onSelectProduct(e, "code")
                      }
                    },
                    model: {
                      value: _vm.form.productCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productCode", $$v)
                      },
                      expression: "form.productCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_product_name") } },
                [
                  _c("CSelectMasterProductName", {
                    attrs: { "prop-search-by": _vm.searchByProduct },
                    on: {
                      "on-select": function(e) {
                        return _vm.onSelectProduct(e, "name")
                      }
                    },
                    model: {
                      value: _vm.form.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productName", $$v)
                      },
                      expression: "form.productName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_uom") } },
                [
                  _c("CSelectUomConv", {
                    attrs: { "prop-product-id": _vm.form.productId },
                    model: {
                      value: _vm.form.uomId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "uomId", $$v)
                      },
                      expression: "form.uomId"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "save",
              attrs: { type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }