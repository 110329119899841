



















































import Vue from "vue";
export default Vue.extend({
  name: "ModalAddConsumeProduct",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    propParentProduct: {
      type: Array as () => string[],
      default: undefined,
    }
  },
  data() {
    return {
      form: {
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: "",
        uomId: undefined as string | undefined,
      }
    };
  },
  computed: {
    searchByProduct() {
      let str: string[] = [];
      if (!this.propParentProduct.length) return "";
      this.propParentProduct.forEach(x => {
        str.push(`code~${x}*`);
      });
      return str.join("_OR_");
    }
  },
  methods: {
    close(): void {
      this.$emit("input", false);
    },
    save(): void {
      this.$emit("on-save", { value: this.form });
      this.$emit("input", false);
    },
    onSelectProduct(e, source): void {
      if (source === "code") {
        this.form.productName = e.meta.meta.name;
      } else {
        this.form.productCode = e.meta.meta.code;
      }
      this.form.productId = e.meta.meta.id;
    }
  }
});
